import React, { useState } from "react";
import { Link } from "react-scroll";
import { Link as Link2 } from "react-router-dom";

import Logo from "../assets/images/logo.png";

import BackgroudImage from "../assets/images/bg/index.png";
import Services from "../component/Services";
import About from "../component/About";
import Pricing from "../component/Pricing";
import Contact from "../component/Contact";
import Footer from "../component/Footer";

/**
 * Index Component
 */
export default function Index() {

  const [isOpen, ] = useState(true);
  window.addEventListener("scroll", windowScroll);

  ;


  /**
   * Window scroll
   */
  function windowScroll() {
    const navbar = document.getElementById("navbar");
    if (
      document.body.scrollTop >= 50 ||
      document.documentElement.scrollTop >= 50
    ) {
      navbar.classList.add("is-sticky");
    } else {
      navbar.classList.remove("is-sticky");
    }
  }

  return (
    <>
      <div>
        <nav className="navbar" id="navbar">
          <div className="container flex flex-wrap items-center justify-end">
            <a className="navbar-brand" href="#">
              <span className="inline-block dark:hidden">
                <img src={Logo} className="l-dark" style={{ width: '100px', height: 'auto' }}/>
                <img src={Logo} className="l-light" style={{ width: '100px', height: 'auto' }} />
              </span>
              <img
                src={Logo}
                className="hidden dark:inline-block"
                alt=""
              />
            </a>

            <div className="nav-icons flex items-center lg_992:order-2 ms-auto">
              <ul className="list-none menu-social mb-0">
                <li className="inline ms-1">
                </li>
                <li className="inline ms-1">
                  <Link2 to="https://www.facebook.com/VPinmobiliaria/">
                    <span className="login-btn-primary">
                      <span className="btn btn-sm btn-icon rounded-full bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white">
                        <i className="uil uil-facebook"></i>
                      </span>
                    </span>
                    <span className="login-btn-light">
                      <span className="btn btn-sm btn-icon rounded-full bg-gray-50 hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700">
                        <i className="uil uil-facebok"></i>
                      </span>
                    </span>
                  </Link2>
                </li>
                <li className="inline ms-1">
                  <Link2 to="https://www.instagram.com/valdepenas_inmobiliaria/">
                    <span className="login-btn-primary">
                      <span className="btn btn-sm btn-icon rounded-full bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white">
                        <i className="uil uil-instagram"></i>
                      </span>
                    </span>
                    <span className="login-btn-light">
                      <span className="btn btn-sm btn-icon rounded-full bg-gray-50 hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700">
                        <i className="uil uil-instagram"></i>
                      </span>
                    </span>
                  </Link2>
                </li>
              </ul>
            </div>

            <div
              className={`${isOpen === true ? 'hidden' : 'block'} navigation lg_992:order-1 lg_992:flex`}
              id="menu-collapse"
            >
              <ul className="navbar-nav nav-light" id="navbar-navlist">
                <Link
                  className="nav-item"
                  to="home"
                  activeclassname="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <span className="nav-link">Inicio</span>
                </Link>
                <Link
                  className="nav-item"
                  activeclassname="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                  to="about"
                >
                  <span className="nav-link">Sobre Nosotros</span>
                </Link>
                <Link
                  className="nav-item"
                  to="features"
                  activeclassname="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <span className="nav-link">Amenidades y Servicios</span>
                </Link>
                <Link
                  className="nav-item"
                  to="pricing"
                  activeclassname="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <span className="nav-link">Precios y Financiamiento</span>
                </Link>
                <Link
                  className="nav-item"
                  to="contact"
                  activeclassname="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <span className="nav-link">Contacto</span>
                </Link>
              </ul>
            </div>
          </div>
        </nav>
        <section
          style={{ backgroundImage: `url(${BackgroudImage})` }}
          className="py-36 lg:py-64 w-full table relative bg-center bg-cover"
          id="home"
        >
          <div className="absolute inset-0 bg-black opacity-80"></div>
          <div className="container relative">
            <div className="grid grid-cols-1 mt-12">
              <h4 className="text-white lg:text-5xl text-4xl lg:leading-normal leading-normal font-medium mb-7 position-relative">
                Bienvenidos a <br />
                Inmobiliria Valdepeñas
              </h4>

              <p className="text-white opacity-50 mb-0 max-w-2xl text-lg">
              Su socio de confianza en la búsqueda de la casa perfecta en Tulancingo. <br />Con una trayectoria destacada en el desarrollo de viviendas, nos comprometemos a ofrecer propiedades de alta calidad que superen sus expectativas.
              </p>
            </div>
          </div>
        </section>

        {/* About section */}
        <About />

        {/* Service section */}
        <Services />

        {/* Pricing section */}
        <Pricing />

        {/* Contact section */}
        <Contact />

        {/* Footer section */}
        <Footer />
      </div>

    </>
  );

}

