import React, { useState } from "react";

import ModalVideo from "react-modal-video";
import about from "../assets/images/CASAROSALESPEJEADAS.png";
import "../../node_modules/react-modal-video/scss/modal-video.scss";

export default function About() {
  const [isOpen, setOpen] = useState(false)

  return (
    <>
      <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="about">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 gap-10 items-center">
            <div className="lg:col-span-5">
            <img src={about} alt="Descripción de la imagen" className="w-full h-auto" />
            </div>
            {/* end col */}

            <div className="lg:col-span-7">
              <div className="lg:ms-7">
                <h6 className="text-orange-600 text-base font-medium uppercase mb-2">Sobre Nosotros</h6>

                <p className="text-slate-400 dark:text-slate-300 max-w-2xl mx-auto">Con años de experiencia en el mercado inmobiliario, Valdepeñas se destaca por crear viviendas y lotes que no solo cumplen con las necesidades familiares sino que las superan, gracias a nuestro enfoque en calidad, funcionalidad y diseño innovador.</p>

                
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End --> */}
    </>
  );

}

