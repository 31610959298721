import React from "react";
import { Link } from "react-router-dom";

import StrategyImage from "../assets/images/svg/design-thinking.svg";
import Propieades from "../assets/images/propoedades.jpg";

export default function Services() {
  const team = [
    {
      title: 'Areas verdes',
      icon: 'adjust-circle',
    },
    {
      title: 'Zonas de mascotas',
      icon: 'circuit',
    },
    {
      title: 'Juegos infantiles',
      icon: 'adjust-circle',
    },
    {
      title: 'Pistas de jogging',
      icon: 'shopping-basket',
    },
    {
      title: 'Seguridad de una comunidad',
      icon: 'flower',
    },
    {
      title: 'Acceso controlado',
      icon: 'flip-h',
    }
  ]

  return (
    <>
      {/* Start */}
      <section className="relative md:py-24 py-16 active" id="features">
        <div className="container lg mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 pb-8 items-center">
            <div>
              <h6 className="text-orange-600 text-base font-medium uppercase mb-2">Amenidades y Servicios</h6>
            </div>
            <div>
              <p className="text-slate-400 dark:text-slate-300 max-w-xl">Viva en un entorno lleno de comodidades, disfrute de nuestras:</p>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-6">

            {team.map((item, key) => (
              <div key={key} className={`features p-6 ${key % 2 === 0 ? "hover:shadow-xl hover:shadow-slate-100 dark:hover:shadow-slate-800" : "shadow-xl shadow-slate-100 dark:shadow-slate-800"} transition duration-500 rounded-3xl mt-8`}>
                <div className="w-20 h-20 bg-orange-600/5 text-orange-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm">
                  <i className={`uil uil-${item.icon}`}></i>
                </div>

                <div className="content mt-7">
                  <Link to="#" className="text-lg hover:text-orange-600 dark:text-white dark:hover:text-orange-600 transition-all duration-500 ease-in-out font-medium">{item.title}</Link>
                  <p className="text-slate-400 mt-3">{item.subtext}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="container md:mt-24 mt-16">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h6 className="text-orange-600 text-base font-medium uppercase mb-2">Nuestras Propiedades</h6>
            <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">Te ofrecemos</h3>
            <p className="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">Casas y lotes en venta ubicados en las prestigiosas <strong>Calle Rosal y Tilo</strong>, diseñados para enriquecer su estilo de vida.  <br />Cada lote, de <strong>105 m² (7 m x 15 m)</strong>, está pensado para construir el hogar de sus sueños.</p>
          </div>

          <div className="grid grid-cols-1 mt-8">
            <div className="timeline relative">
              <div className="timeline-item">
                <div className="grid sm:grid-cols-2">
                  <div className="">
                    <div className="duration date-label-left ltr:float-right rtl:float-left md:me-7 relative">
                      <img src={StrategyImage} className="h-64 w-64" alt="" />
                    </div>
                  </div>
                  <div className="mt-4 md:mt-0">
                    <div className="event event-description-right ltr:float-left rtl:float-right ltr:text-left rtl:text-right md:ms-7">
                      <h5 className="text-lg dark:text-white mb-1 font-medium">Compromiso con la Calidad</h5>
                      <p className="timeline-subtitle mt-3 mb-0 text-slate-400">Nos dedicamos a supervisar cada detalle del proceso constructivo, colaborando con laboratorios certificados para garantizar la resistencia y durabilidad de nuestros materiales, asegurando así, la entrega de viviendas seguras y confiables.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );

}
