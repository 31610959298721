import React from "react";

export default function Contact() {
    return (
        <>
            {/* Start */}
            <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="contact">
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h6 className="text-orange-600 text-base font-medium uppercase mb-2">Contacto</h6>
                        <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">¿Listo para dar el próximo paso hacia su hogar ideal?</h3>

                        <p className="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">Contáctenos y permita que Inmobiliaria Valdepeñas le guíe en cada etapa del proceso de compra. Estamos aquí para hacer realidad su sueño de vivienda.</p>
                    </div>
                </div>
            </section>
        </>
    );

}
